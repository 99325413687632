/**
 * focus on the next item
 */

import $ from 'jquery';

export default {
	now () {

		// add all elements we want to include in our selection
		const
			focussableElements = 'a:not([disabled]), li:not([disabled]), button:not([disabled]), input[type="text"]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])',
			form = $(document.activeElement).parents('.select-contain');

		if (document.activeElement && form) {
			const
				focussable = Array.prototype.filter.call(
					form.find(focussableElements),

					function (element) {
						//check for visibility while always include the current activeElement
						return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement;
					}
				),
				index = focussable.indexOf(document.activeElement);

			focussable[index + 1].focus();
		}
	}
};
