const rootRegEx = /^(?:[a-z0-9][a-z0-9-]*\.)+[a-z]{2,}$/i;
const wwwRegEx = /^www\.(?:[a-z0-9][a-z0-9-]*\.)+[a-z]{2,}$/i;

// Check if the domain exists in the given array
const checkIfExists = (domainsArray, domain) => {
	return domainsArray.includes(domain.toLowerCase());
};

const checkIfLocalSite = (domain) => {
	return domain.match(/\.local(host)?$/);
};

// Accepts Vue's `this.$route.query` object
const createVueQueryString = (queryObj) => {
	let str = '';
	let count = 0;
	let char = '&';
	const total = Object.entries(queryObj).length;

	for (const [key, value] of Object.entries(queryObj)) {
		count += 1;
		if (count === total) {
			char = '';
		}
		str += `${key}=${value}${char}`;
	}
	return str;
};

const isRootDomain = (val) => {
  return (val.match(rootRegEx) && !val.match(wwwRegEx)) ? true : false;
};

const isWwwDomain = (val) => {
  return (val.match(wwwRegEx) !== null) || false;
};

// Removes `http/s` protocol from input value [url] and returns the modified url string
const removeProtocol = (val) => {
	const httpStr = 'http://';
	const httpsStr = 'https://';

	if (val.indexOf(httpStr) === 0) {
		val = val.replace(httpStr, '');
	} else if (val.indexOf(httpsStr) === 0) {
		val = val.replace(httpsStr, '');
	}
	return val;
};

const getBftPointedStatus = (response) => {
	let pointedStatus = 'Not Updated';
	const determineInProgress = (response) => {
		return (
			!response.data.pointed_to_cloud &&
			response.data.report_data.valid &&
			response.data.report_data.supported &&
			response.data.report_data.base_sync_uri &&
			response.data.sync_request_completed &&
			!response.data.sync_request_expired
		);
	};
	const inProgress = determineInProgress(response);

	const setPointedStatus = (response, inProgress) => {
		if (response.data.pointed_to_cloud) {
			pointedStatus = 'Updated';
			return;
		}

		if (inProgress) {
			pointedStatus = 'In Progress';
			return;
		}
	};
	setPointedStatus(response, inProgress);
	return pointedStatus;
};

export {
	checkIfExists,
	checkIfLocalSite,
	createVueQueryString,
	isRootDomain,
	isWwwDomain,
	removeProtocol,
	getBftPointedStatus
};
