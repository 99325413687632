/**
 * Enhancements/modifications to jQuery validation
 * We use the jQuery Validation plugin for all front-end form validation
 * this file is just for overriding defaults and extending
 */

import $ from 'jquery';
import 'jquery-validation';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueValidationDictionary from 'helpers/validation-dictionary';

export default {
	init () {
		this.vueValidationSetup();
		this.jQueryValidationRules();
	},

	vueValidationSetup () {
		Vue.use(VeeValidate, {
			classes: true,
			dictionary: VueValidationDictionary
		});
	},

	jQueryValidationRules () {
		$.validator.addMethod('valueNotEquals', (value, element, arg) => {
			return arg !== $(element).find('option:selected').text();
		}, 'Value must not equal arg.');

		$.validator.addMethod('valueEquals', (value, element, arg) => {
			return arg === $(element).text();
		}, 'Value must equal arg.');

		$.validator.addMethod('greaterThanZero', (value, element) => {
			return value > 0;
		}, 'Please enter at least one service below');

		$.validator.addMethod('notNegativeValue', (value, element) => {
			return value >= 0;
		}, 'Total must not be negative');

		// Checks for acceptable hex colors
		$.validator.addMethod('hexRegEx', (value, element, regexp) => {
			if (regexp.constructor !== RegExp) {
				regexp = new RegExp(regexp);
			} else if (regexp.global) {
				regexp.lastIndex = 0;
			}
			return regexp.test(value);
		}, 'Please enter a valid hex color.');

		// Checks for positive dollar amounts
		$.validator.addMethod('positiveAmount', (value, element, regexp) => {
			if (regexp.constructor !== RegExp) {
				regexp = new RegExp(regexp);
			} else if (regexp.global) {
				regexp.lastIndex = 0;
			}

			return regexp.test(value);
		}, 'Please enter an amount without currency symbols or commas.');

		$.validator.setDefaults({
			showErrors (errorMap, errorList) {
				this.errorList = $.grep(errorList, (error) => {
					return typeof error.element !== 'undefined';
				});

				this.defaultShowErrors();
			}
		});

		// Don't allow special characters
		$.validator.addMethod('alphanumeric', function (value, element) {
			return this.optional(element) || (/^[0-9a-zA-Z \b]+$/i).test(value);
		}, 'No special characters allowed');

		$.validator.addMethod('slug', function (value, element) {
			return this.optional(element) || (/^[a-z0-9]+(?:\-[a-z0-9]+)*$/).test(value);
		}, 'Can only contain lowercase letters, numbers, and dashes, and cannot end in a dash');

		// URL validation that doesn't require http:// or https://
		$.validator.addMethod('smartUrl', function (value, element) {
			const urlRegex = /^(?:(?:(?:https?):)?\/\/)?(?:\S+(?::\S*))?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

			return this.optional(element) || urlRegex.test(value);
		}, 'Please enter a valid URL');

		// Check if first character is NOT an integer
		$.validator.addMethod('firstCharacterNotInteger', (value) => {
			const nonIntegerRegex = /^[a-zA-Z].*/;
			return nonIntegerRegex.test(value);
		}, 'First character must be a letter');

		// Check if phone number uses correct regex (blocks letters and most special characters)
		$.validator.addMethod('phoneRegex', function (value, element) {
			return this.optional(element) || (/^[0-9-+() ]*$/).test(value);
		}, 'Please enter a valid phone number.');

		// Check if the value is in not allowed list case insensitive
		$.validator.addMethod('valuesNotAllowed', (value, element, excluded_values) => {
			return !excluded_values.includes(value.toLowerCase());
		}, 'This value is not allowed');

		// Check if select component is chosen and add error-input class to the component if it is not
		$.validator.addMethod('selectComponentPresence', (value, element) => {
			let exists;

			if (element.value === '') {
				exists = true;

				if (element.classList.contains('error')) {
					const parent = element.parentNode;
					let anchor = null;
					if (parent) {
						anchor = parent.querySelector('a.select');
						anchor.classList.add('error-input');
					}
				}
			} else {
				exists = false;

				if (element.classList.contains('error')) {
					const parent = element.parentNode;
					let anchor = null;
					if (parent) {
						anchor = parent.querySelector('a.select');
						anchor.classList.remove('error-input');
					}
				}
			}

			return !exists;

		}, 'This field is required.');
	}
};
