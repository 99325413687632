/**
 * Generate JWT tokens for HTTP request auth
 */

import $ from 'jquery';

let crypto = null;

export default {
	// Constants for JWT header and payload
	AUTHSETTINGS: {
		'payload': {
			'exp': '',
			'key': $('body').data('token')
		}
	},

	// Base64 URL encoding
	base64url (source) {
		// Encode in classical base64
		let encodedSource = crypto.enc.Base64.stringify(source);

		// Remove padding equal characters
		encodedSource = encodedSource.replace(/=+$/, '');

		// Replace characters according to base64url specifications
		encodedSource = encodedSource.replace(/\+/g, '-');
		encodedSource = encodedSource.replace(/\//g, '_');

		return encodedSource;
	},

	// Kick things off
	init () {
		// Initially generate auth token
		this.generateToken();

		// Regenerate new token every 5 minutes
		this.timeoutLoop();
	},

	timeoutLoop () {
		setTimeout(() => {
			this.generateToken();
			this.timeoutLoop();
		}, 300000);
	},

	// Generate a JWT token using the CryptoJS library
	// Taken from http://www.jonathan-petitcolas.com/2014/11/27/creating-json-web-token-in-javascript.html
	generateToken () {
		return new Promise((resolve, reject) => {
			import(/* webpackChunkName: "crypto-js" */ 'crypto-js').then(CryptoJS => {
				crypto = CryptoJS;

				const
					oldDate = new Date(),
					stringifiedHeader = crypto.enc.Utf8.parse(JSON.stringify({'alg': 'HS256', 'typ': 'JWT'})),
					encodedHeader = this.base64url(stringifiedHeader),
					stringifiedData = crypto.enc.Utf8.parse(JSON.stringify({'exp': new Date(oldDate.getTime() + (5 * 60000)).getTime(), 'key': $('body').data('token')})),
					encodedData = this.base64url(stringifiedData),
					token = `${encodedHeader}.${encodedData}`,
					secret = $('body').data('secret'),
					signature1 = new crypto.HmacSHA256(token, secret),
					signature2 = this.base64url(signature1),
					signedToken = `${token}.${signature2}`;

				// Set expiration date

				resolve(this.setAjaxHeader(signedToken));
			});
		});
	},

	// Update Backbone's Ajax header
	setAjaxHeader (token) {
		const requestHeaders = {
			'FW-Authorization': token,
			'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN
		};

		return requestHeaders;
	}
};
