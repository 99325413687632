/**
 * Overrides to VeeValidate's built-in error messages
 * Documentation: https://baianat.github.io/vee-validate/guide/messages.html
 */

export default {
	en: {
		messages:{
			required: 'This field is required',
			numeric: 'Must contain only numbers',
			email: 'This field must be a valid email',
			confirmed: 'The confirmation does not match',
			sshKey: 'Key must start with ssh-rsa or ssh-ed25519',
			phone: 'Must be a valid phone number',

			max (fieldName, params) {
				return `Can't be longer than ${params[0]} characters`;
			},

			min (fieldName, params) {
				return `Must be at least ${params[0]} characters`;
			}
		}
	}
};
