/**
 * Helper for retrieving window.location.href
 * Allows us to properly test window.location in our code
 */

export default {
	get () {
		return window.location.href;
	},

	set (url) {
		window.location.href = url;
	}
};
