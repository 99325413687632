export default {
	difference: (val1, val2) => {
		const data1 = parseFloat(val1);
		const data2 = parseFloat(val2);
		const hasIncreased = data1 > data2;
		const operation = hasIncreased ? ((data2 / data1) -1) : ((data1 / data2) - 1);
		const diff = operation * 100;
		const rounded = Math.round(diff * 100) / 100;
		const result = hasIncreased ? Math.abs(rounded) : -Math.abs(rounded);
		return result || 0;
	}
}
