import Mustache from 'mustache';

const cache = new Map();

export const interpolate = Mustache.render;

export const fetch = (key, fallback = null) => {
	if (cache.has(key)) {
		return cache.get(key);
	}

	const node = document.querySelector(`script[type="text/x-mustache-template"][name="${key}"]`);
	if (node) {
		cache.set(key, node.innerText);
		return node.innerText;
	}

	if (console && ('warn' in console)) {
		// eslint-disable-next-line no-console
		console.warn(`I18n key not found: ${key}`);
	}

	return (typeof fallback === 'function') ? fallback(key) : fallback;
}

const countable = (key, count, fallback) => {
	switch (count) {
		case undefined:
			return [key, fallback];
		case 0:
			return [`${key}.zero`, () => fetch(`${key}.other`, fallback)];
		case 1:
			return [`${key}.one`, fallback];
		default:
			return [`${key}.other`, fallback];
	}
}

export const translate = (key, {count, ...options} = {}, fallback = null) => {
	[key, fallback] = countable(key, count, fallback);
	let translation = fetch(key, fallback);
	if (translation) {
		return interpolate(translation, {count, ...options});
	}
}

export default { interpolate, fetch, translate, t: translate }
