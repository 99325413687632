const flatten = (list) => list.reduce(
    (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []
);

const includes = (list, key) => list.indexOf(key) > -1;
const excludes = (list, key) => !includes(list, key);

const isEqual = (arr1, arr2) => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	const uniqueValues = new Set([...arr1, ...arr2]);
	for (const val of uniqueValues) {
		const firstCount = arr1.filter((originalVal) => originalVal === val).length;
		const secondCount = arr2.filter((originalVal) => originalVal === val).length;
		return firstCount === secondCount;
	}

	return true;
};

export { flatten, includes, excludes, isEqual };
