const avatar = (avatar, fallback) => {
	if (avatar && avatar.includes('.png') && !avatar.includes('space')) {
		return 'white';
	}

	const colors = ['yellow', 'orange', 'red', 'pink', 'purple'];
	return colors[fallback.length % colors.length];
};

export default avatar;
