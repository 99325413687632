// Extracted from [Modernizr](https://github.com/Modernizr/Modernizr/blob/db96bdaff995a1d4abccb0dc69c77db7b47ad614/feature-detects/websockets.js)
export const hasWebSockets = () => {
	let supports = false;

	try {
		supports = 'WebSocket' in window && window.WebSocket.CLOSING === 2;
	// eslint-disable-next-line no-empty
	} catch (_e) {}

	return supports;
};
