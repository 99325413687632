/**
 * Determines the root path for a Growth Suite account from current path.
 *
 * @example
 * // returns '/org/dancing-cow-software'
 * accountRoot('/org/dancing-cow-software/invoice/new')
 *
 * @param {string} currentPath - The current path of the page. Defaults to `window.location.pathname`.
 * @return {string}
 */
export const accountRoot = (currentPath) => {
	const extractor = new RegExp('^/(?:org/)?[^\/]+');

	if (typeof currentPath === 'undefined') {
		currentPath = window.location.pathname;
	}

	return extractor.exec(currentPath) ? extractor.exec(currentPath)[0] : '/';
};

export const orgSlug = () => {
	const path = accountRoot();

	return path.split('/')[2];
};

const orgPath = accountRoot();

export const orgSettingsUrl = () => {
	return `${orgPath}/settings`;
};

export const newInvoiceUrl = (clientSlug) => {
	if (clientSlug) {
		return `${orgPath}/clients/${clientSlug}/invoice/new`;
	}

	return `${orgPath}/invoice/new`;
};

export const newServiceUrl = () => {
	return `${orgPath}/services/new`;
};

export const newClientUrl = () => {
	return `${orgPath}/clients/new`;
};

export const clientIndexUrl = () => {
	return `${orgPath}/clients`;
};

export const newSiteUrl = (orgSlug) => {
	return `/new-site?for=${orgSlug}`;
};

export const reportIndexUrl = () => {
	return `${orgPath}/reports`;
};

export const newReportUrl = () => {
	return `${orgPath}/reports/new/select-template`;
};
