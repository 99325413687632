export function debounce (func, wait, immediate) {
	// Returns a function, that, as long as it continues to be invoked,
	// will not be triggered. The function will be called after it stops
	// being called for N milliseconds. If `immediate` is passed,
	// trigger the function on the leading edge, instead of the trailing.
	let result;
	let timeout = null;

	return () => {
		const context = this;
		const args = arguments;

		const later = () => {
			timeout = null;

			if (!immediate) {
				result = func.apply(context, args);
			}
		};

		const callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

		if (callNow) {
			result = func.apply(context, args);
		}

		return result;
	};
};
