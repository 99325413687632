import axios from 'axios';
import notif from 'components/notification';

export default {
	init() {
		const resendEmailButton = document.querySelector('#email-verification-alert a.button');
		if (resendEmailButton) {
			resendEmailButton.addEventListener('click', (event) => this.makeRequest(event));
		}
	},

	makeRequest(event) {
		event.preventDefault();
		event.stopPropagation();
		event.currentTarget.setAttribute('disabled', true);
		event.currentTarget.blur();

		axios
			.get(event.currentTarget.href, {
				headers: {
					'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN,
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				}
			})
			.then((response) => {
				notif.show({
					message: response.data.action.notice,
					type: 'success',
					float: true
				});
			});
	}
};
