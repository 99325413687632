/**
 * Polyfills
 */

import 'nodelist-foreach-polyfill';

// Polyfill for startsWith
const startsWithPolyfill = function () {
	if (!String.prototype.startsWith) {
		String.prototype.startsWith = function (searchString, position) {
			position = position || 0;
			return this.indexOf(searchString, position) === position;
		};
	}
};

// Polyfill for includes
const includesPolyfill = function () {
	if (!String.prototype.includes) {
		String.prototype.includes = function (search, start) {
			if (typeof start !== 'number') {
				start = 0;
			}

			if (start + search.length > this.length) {
				return false;
			} else {
				return this.indexOf(search, start) !== -1;
			}
		};
	}
};

export default function () {
	startsWithPolyfill();
	includesPolyfill();
}
