import moment from 'moment-timezone';

const utcTimePeriods = [0, 6, 12, 18]
const weekDay = {
	1: 'monday',
	2: 'tuesday',
	3: 'wednesday',
	4: 'thursday',
	5: 'friday',
	6: 'saturday',
	7: 'sunday',
};

function formatTimePeriodValue (start) {
	const end = start + 6 >= 24 ? start - 18 : start + 6;
	return `${moment(start, "HH").format("HH:mm")} - ${moment(end, "HH").format("HH:mm")}`;
}

function offsetUtcTimePeriods (timezone) {
	let offsetPeriods = [];

	utcTimePeriods.forEach(function(utcPeriod) {
		let currentUtcTime = moment.utc();
		currentUtcTime.hour(utcPeriod);
		let adjustedHour = moment.tz(currentUtcTime.format(), timezone).hour();

		offsetPeriods.push({utc: utcPeriod, local: adjustedHour});
	});

	offsetPeriods.sort((a, b) => a.local - b.local);

	return offsetPeriods;
}

export function formatDaySelectedInWeek(currentTimezone, selectedTimezone, timePeriods, selectedDayWeekly) {
	const filtered = timePeriods[0].items.filter(item => item.selected === true);
	const timePeriod = filtered[0].title.split(' - ');

	const date = new Date();
	const newDate = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${timePeriod[0]}`);

	let dayOfCurrentTimezone = 0;
	let dayOfSelectedTimezone = 0;
	let adjustedDay = [];

	if(currentTimezone.value != selectedTimezone.value) {
		dayOfCurrentTimezone = moment(newDate).isoWeekday();
		dayOfSelectedTimezone = moment(newDate.toLocaleString('en-US', { timeZone: selectedTimezone.value })).isoWeekday();
	}

	const dayDifference = (dayOfSelectedTimezone - dayOfCurrentTimezone) % 7;

	Object.keys(weekDay).forEach(key => {
		if (dayDifference != 0) {
			selectedDayWeekly.forEach((day, index) => {
				if(weekDay[key] === day) {
					const total = (parseInt(key) + parseInt(dayDifference)) % 7;
					(total === 0) ? adjustedDay.push(weekDay[7]) : adjustedDay.push(weekDay[total]);
				}
			})
		}
		else {
 			adjustedDay = selectedDayWeekly;
 		}
	});

 	return adjustedDay;
}

export function offsetTimePeriodValues (selectedTimezone, autoupdateAt) {

	const formattedArray = offsetUtcTimePeriods(selectedTimezone).map(period => ({
		title: formatTimePeriodValue(period.local),
		value: period.utc,
		selected: period.utc == autoupdateAt
	}));

	return [{ items: formattedArray }];
}

export function removeEmptyStringFromSelectedDays (daysOfWeek, arr, frequency=null) {
	const days = Object.values(JSON.parse(JSON.stringify(daysOfWeek)));
	arr = arr.filter(val => days.indexOf(val) !== -1);

	return arr.length === 0 ? frequency === "daily" ? days : arr : arr;
}
