/**
 * Global message displayer
 * used for error, success, etc. messages
 *
 * PARAMS object:
 * id (selector without the #)
 * message (string - the message to display)
 * type (string - 'success' or 'error')
 *
 * EXAMPLE:
 	import notif from 'components/notification';

	notif.show({
		id: 'something',
		message: 'Uh oh, something went wrong.',
		type: 'error'
	});
 */
import parents from 'helpers/dom/parents';

const defaults = {
	id: 'alert',
	message: '',
	type: 'success',
	fade: false,
	float: false,
	floatFade: true
};

const elements = {
	close: document.querySelectorAll('.alert__close'),
	floatedAlert: document.getElementById('alert-floated')
};

export default {
	init () {
		this.bindClose();
	},

	bindClose () {
		// Bind to close icon
		elements.close.forEach((link) => {
			link.addEventListener('click', (event) => {
				event.preventDefault();
				event.stopPropagation();
				this.hide(event.target);
			});
		});
	},

	show (options) {
		let el;

		// Merge defaults with options
		this.options = $.extend({}, defaults, options);

		// Remove any previous classes
		if (elements.floatedAlert) {
			elements.floatedAlert.classList.remove('--active', '--success', '--error');
		}

		if (this.options.float) {
			el = elements.floatedAlert;

			el.classList.add('--floating');

			// a tiny delay if it's floating
			// in order to get that floating class on
			// before the active class
			setTimeout(() => {
				el.classList.add('--active', `--${this.options.type}`);
			}, 50);

			// Fade out after a few seconds
			if (this.options.floatFade) {
				setTimeout(() => {
					el.classList.remove('--active');
				}, 5000);
			}
		} else {
			el = document.getElementById(this.options.id);
			el.classList.add('--active', `--${this.options.type}`);
		}

		if (el.querySelector('p')) {
			el.querySelector('p').innerHTML = this.options.message;
		} else {
			$(`<p>${this.options.message}</p>`).appendTo('#alert-floated .alert__centered');
		}

		// Fade out after period of time if set
		if (this.options.fade) {
			setTimeout(() => {
				el.classList.remove('--active', '--success', '--error');
			}, 3000);
		}
	},

	hide (element) {
		if (element) {
			const parentAlert = parents(element, 'alert');

			if (parentAlert) {
				parentAlert.classList.remove('--active');
			}
		}

		if (elements.floatedAlert) {
			elements.floatedAlert.classList.remove('--active');
		}
	}
};
