// Used to get the human readable month from a js Date object
// Example:
// 		const monthIndex = new Date().getMonth();
// 		const monthName = months[monthIndex];
export default [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
