import axios from 'axios';

export default {
	init () {
		this.dismissIcons = document.querySelectorAll('.async-dismiss');
		this.bindElements(this.dismissIcons);
	},

	bindElements (dismissIcons) {
		dismissIcons.forEach((element) => {
			element.addEventListener('click', (event) => { this.makeRequest(event) });
		});
	},

	makeRequest (event) {
		axios({
				headers: { 'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN },
				method: event.currentTarget.dataset.method,
				url: event.currentTarget.href
			}
		);
	}
};
