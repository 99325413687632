/**
 * Helpers specifically for synchronized Highcharts
 * Functions mostly copied from the official Highcharts synchronized charts demo, here:
 * https://www.highcharts.com/demo/synchronized-charts
 */

let self = null;

export default {
	listenerFunction(e) {
		let point;
		let event;

		self.Highcharts.charts.forEach((chart) => {
			if (chart) {
				event = chart.pointer.normalize(e);
				point = chart.series[0].searchPoint(event, true);

				if (point) {
					point.highlight(e);
				}
			}
		});
	},

	init() {
		const chartEl = document.getElementById('synchronized-charts');

		self = this;

		if (chartEl) {
			// eslint-disable-next-line no-inline-comments
			import(/* webpackChunkName: "highcharts" */ 'highcharts').then((Highcharts) => {
				self.Highcharts = Highcharts;

				//Syncs up the touch/move events between the charts
				['mousemove', 'touchmove', 'touchstart'].forEach((eventType) => {
					chartEl.removeEventListener(eventType, this.listenerFunction, true);
					chartEl.addEventListener(eventType, this.listenerFunction, true);
				});

				// eslint-disable-next-line no-undefined
				Highcharts.Pointer.prototype.reset = () => undefined;

				Highcharts.Point.prototype.highlight = function (event) {
					event = this.series.chart.pointer.normalize(event);
					this.onMouseOver();
					this.series.chart.tooltip.refresh(this);
					this.series.chart.xAxis[0].drawCrosshair(event, this);
				};
			});
		}
	}
};
