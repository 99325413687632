/**
 * Helpers
 */

import fonts from 'helpers/fonts';
import jqueryExp from 'helpers/jquery-expressions';
import polyfills from 'helpers/polyfills';
import validation from 'helpers/validation';

export default (function () {
	fonts();
	jqueryExp();
	polyfills();
	validation.init();
}());
