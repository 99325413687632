const styles = {
	'input': {
		'cursor': 'text',
		'color': '#262727',
		'font-size': '14px',
		'font-family': '"Museo Sans Rounded"',
		'-webkit-font-smoothing': 'antialiased'
	},
	':focus': {
		'color': '#262727'
	},
	'.invalid': {
		'color': '#ef4e65'
	}
};

const fields = {
	number: {
		selector: '#credit_card_form_cc_number',
		placeholder: '4111 1111 1111 1111'
	},
	cvv: {
		selector: '#credit_card_form_cvv',
		placeholder: '123'
	},
	expirationMonth: {
		selector: '#credit_card_form_expiration_month',
		placeholder: 'MM'
	},
	expirationYear: {
		selector: '#credit_card_form_expiration_year',
		placeholder: 'YYYY'
	},
	postalCode: {
		selector: '#credit_card_form_postal_code',
		placeholder: '11111'
	}
};

export { styles, fields };
