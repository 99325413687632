/**
 * Enhancements of certain jQuery expressions
 */

import $ from 'jquery';

export default function () {
	// Make :contains case insensitive
	$.expr[':'].contains = $.expr.createPseudo(function (arg) {
		return function (el) {
			return $(el).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
		};
	});
};
