import { getOffsetTop } from 'helpers/get-offset-top';

export const scrollToElement = (elem, offset = 0) => {
	const header = document.querySelector('header.header');
	const isHeaderFixed = window.getComputedStyle(header).position === 'fixed';
	const headerOffset = isHeaderFixed ? header.offsetHeight : 0;
	const top = getOffsetTop(elem) - headerOffset - 60 - offset;
	window.scrollTo({
		top,
		behavior: 'smooth'
	});
};
