const calcOpacity = (index) => {
	let opacity = '';

	switch (index) {
		case 0:
			opacity = 'FF';
			break;
		case 1:
			opacity = '66';
			break;
		case 2:
			opacity = 'CC';
			break;
		case 3:
			opacity = '33';
			break;
		case 4:
			opacity = '99';
			break;
	}

	return opacity;
};

export { calcOpacity };
