// https://css-tricks.com/working-with-javascript-media-queries/
// https://stackoverflow.com/questions/56466261/matchmedia-addlistener-marked-as-deprecated-addeventlistener-equivalent
// queries: an array of query strings to add to the window
// callback: callback function to call when mediaQueries fire (will be the same for all queries passed)
// note the event that will be passed to the functinon is the mediaQueryList object (can check if "matches" is true, for instance)
// returns an array of the created media queries 

const DEFAULTQUERIES = [
    '(min-width: 320px)',
    '(min-width: 367px)',
    '(min-width: 414px)',
    '(min-width: 568px)',
    '(min-width: 675px)',
    '(min-width: 768px)',
    '(min-width: 896px)',
    '(min-width: 1024px)',
    '(min-width: 1200px)',
    '(min-width: 1314px)',
    '(min-width: 1650px)'
];

const addMediaQueries = (callback, queries = DEFAULTQUERIES) => {
    let mediaQueries = [];
    queries.forEach((query) => {
        const mediaQuery = window.matchMedia(query);
        mediaQuery.onchange = callback;
        mediaQueries.push(mediaQuery);
    });
    return mediaQueries;
};

export default { addMediaQueries };
