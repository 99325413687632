/**
 * Font loadin with Font Face Observer
 */

import FontFaceObserver from 'fontfaceobserver/fontfaceobserver.js';

export default function () {
	const
		museo = new FontFaceObserver('Museo Sans Rounded'),
		archerA = new FontFaceObserver('Archer SSm A'),
		archerB = new FontFaceObserver('Archer SSm B');

	museo.load().then(() => {
		document.documentElement.className += ' museo-loaded';
		sessionStorage.museoLoaded = true;
	}).catch(() => {
		sessionStorage.museoLoaded = false;
	});

	archerA.load().then(() => {
		document.documentElement.className += ' archer-loaded';
		sessionStorage.archerLoaded = true;
	}).catch(() => {
		sessionStorage.archerLoaded = false;
	});

	archerB.load().then(() => {
		document.documentElement.className += ' archer-loaded';
		sessionStorage.archerLoaded = true;
	}).catch(() => {
		sessionStorage.archerLoaded = false;
	});
}
