export const scrollToBottom = (offset = 0) => {
	const footer = document.querySelector('footer').offsetHeight;
	const subfooter = document.querySelector('section.subfooter__container').offsetHeight;
	const doc = document.body.scrollHeight;
	const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
	const bottom = doc - footer - subfooter - vh - offset;

	window.scrollTo({
		top: bottom,
		behavior: 'smooth'
	});
};

