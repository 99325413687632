const ordinalSuffixed = (n) => {
    if (n > 3 && n < 21) {
        return `${n}th`;
    }

    switch (n % 10) {
        case 1:
        return `${n}st`;
        case 2:
        return `${n}nd`;
        case 3:
        return `${n}rd`;
        default:
        return `${n}th`;
    }
};

const ordinalsByRange = (start, stop, prefix = '', suffix = '') => {
    const result = [];

    [...Array((stop + 1) - start).keys()].forEach((n) => {
        const nWithOffset = n + start;

        result.push(
            {
                id: nWithOffset,
                title: `${prefix} ${ordinalSuffixed(nWithOffset)} ${suffix}`.trim(),
                value: nWithOffset
            }
        );
    });

    return result;
};

export { ordinalsByRange };
