class Money {
	constructor ({ format, fractional }, { iso_code = 'USD' } = {}) {
		this.format = format;
		this.fractional = fractional;
		this.isoCode = iso_code;
	}

	dollars () {
		return this.fractional / 100;
	}
}

export default Money;
