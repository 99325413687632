const defaultStorageType = 'sessionStorage';

export default {
	adapter: null,
	storageType: defaultStorageType,
	unavailMessage: 'Adapter not initialized or browser storage unavailable',

	getItem (key) {
		if (this.adapter) {
			const value = this.unpackValue(this.adapter.getItem(key));
			return this.getItemIfNotExpired(value);
		}
		this.logToConsole(this.unavailMessage);
	},

	getItemIfNotExpired (value) {
		if (this.keyExpired(value)) {
			return null;
		}
		if (value && value.expiresIn && value.value) {
			return value.value;
		}
		return value;
	},

	init (useLocal) {
		if (useLocal) {
			this.storageType = 'localStorage';
		}
		if (this.storageAvailable(this.storageType)) {
			this.adapter = window[this.storageType];
		} else {
			this.logToConsole('No browser storage available');
		}
	},

	keyExpired (value) {
		if (value && value.expiresIn) {
			return new Date(value.expiresIn) < new Date();
		}
		return false;
	},

	logToConsole (message) {
		if (console && ('warn' in console)) {
			// eslint-disable-next-line no-console
			console.warn(message);
			return;
		}
		if (console) {
			// eslint-disable-next-line no-console
			console.log(message);
		}
	},

	prepValue (value) {
		return JSON.stringify(value);
	},

	removeItem (key) {
		if (this.adapter) {
			this.adapter.removeItem(key);
		} else {
			this.logToConsole(this.unavailMessage);
		}
	},

	removeItemIfExpired (key) {
		if (this.adapter) {
			const value = this.unpackValue(this.adapter.getItem(key));
			if (this.keyExpired(value)) {
				this.adapter.removeItem(key);
			}
		} else {
			this.logToConsole(this.unavailMessage);
		}
	},

	setItem (key, value) {
		if (this.adapter) {
			this.adapter.setItem(key, this.prepValue(value));
		} else {
			this.logToConsole(this.unavailMessage);
		}
	},

	setItemWithExpiry (key, value, expiresInMinutes = 30) {
		if (this.adapter) {
			let expiresIn = new Date();
			expiresIn = expiresIn.setMinutes(expiresIn.getMinutes() + expiresInMinutes);
			this.setItem(key, { expiresIn, value });
		} else {
			this.logToConsole(this.unavailMessage);
		}
	},

	storageAvailable (type) {
		let storage;
		try {
			storage = window[type];
			const x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return e instanceof DOMException && (
				// everything except Firefox
				e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === 'QuotaExceededError' ||
				// Firefox
				e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// acknowledge QuotaExceededError only if there's something already stored
				(storage && storage.length !== 0);
		}
	},

	unpackValue (value) {
		try {
			return JSON.parse(value);
		} catch (e) {
			return value;
		}
	}
};
