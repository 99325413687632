export default {
	// Returns the formatted currency string for value to match our Ruby-Money formatting
	formatAsCurrency (value, currencyCode) {
		if ((!value && value !== 0) || !currencyCode) { return value; }

		let locale = 'en-US';

		// Format euros as "10.000,00" instead of "10,000.00" for users with a european locale.
		if (navigator && currencyCode.toLowerCase() === 'eur') {
			locale = navigator.language;
		}

		return Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currencyCode
		}).format(value);
	},

	getCurrencySymbol (currencyCode) {
		const symbol = new Intl.NumberFormat('en', {
			style: 'currency',
			currency: currencyCode
		}).formatToParts().find((x) => x.type === 'currency');

		return symbol && symbol.value;
	}
};
