/* globals Stripe */

/**
 * Stripe initializer
 */

export default {
	init (publishableKey, connectedAccountId) {
		return Stripe(publishableKey, { stripeAccount: connectedAccountId, betas: ['us_bank_account_beta_2'] });
	}
};
