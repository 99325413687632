/**
 * Asynchronously loads an external source file and calls a callback after load.
 *
 * @example <caption>Loads stripe.js and logs "foo" after it loads.</caption>
 *
 * getScript('https://js.stripe.com/v3/', () => {
 *   console.log('foo');
 * });
 *
 * @param {string} path - The path to a source code file to load.
 * @param {function=} callback - A callback to perform once the script is loaded.
 **/
export default (path, callback) => {
	let script = document.createElement('script');
	const prior = document.getElementsByTagName('script')[0];

	script.async = 1;
	script.onload = script.onreadystatechange = (_, isAbort) => {
		if (isAbort || !script.readyState || (/loaded|complete/).test(script.readyState)) {
			script.onload = script.onreadystatechange = null;
			script = undefined; // eslint-disable-line no-undefined

			if (!isAbort && callback) {
				callback();
			}
		}
	};

	script.src = path;

	if (prior) {
		prior.parentNode.insertBefore(script, prior);
	} else {
		document.getElementsByTagName('body')[0].appendChild(script);
	}
};
