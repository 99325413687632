/**
 * Default Highcharts options
 */

export default {
	init () {

		const flywheelFonts = 'Museo Sans Rounded, Helvetica, Arial, sans-serif';

		// eslint-disable-next-line no-inline-comments
		import(/* webpackChunkName: "highcharts" */ 'highcharts').then((Highcharts) => {
			Highcharts.setOptions({
				lang: {
					thousandsSep: ','
				},
				colors: ['#50c6db'],
				chart: {
					type: 'line'
				},
				title: {
					text: null,
					style: {
						fontWeight: '500',
						fontFamily: flywheelFonts
					}
				},
				xAxis: {
					lineColor: '#ebebeb',
					tickColor: '#fff',
					labels: {
						y: 35,
						step: 7,
						staggerLines: 1,
						style: {
							'color': '#262727',
							'font-weight': '500',
							'font-family': flywheelFonts,
							'font-size': '1.8rem'
						}
					}
				},
				yAxis: {
					gridLineColor: '#ebebeb',
					tickColor: '#fff',
					title: {
						text: null
					},
					labels: {
						style: {
							'color': '#c7c4c4',
							'text-transform': 'uppercase',
							'font-weight': 'bold',
							'font-family': flywheelFonts,
							'font-size': '13px'
						}
					}
				},
				legend: {
					enabled: false
				},
				plotOptions: {
					pie: {
						size: 200,
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							align: 'center',
							enabled: true,
							format: '<b>{point.y}%</b>',
							style: {
								fontFamily: 'Museo Sans Rounded, Helvetica, Arial, sans-serif',
								fontSize: '1.8rem',
								textOutline: 'none'
							}
						},
						states: {
							hover: {
								enabled: false
							}
						}
					},
					line: {
						formatter () {
							if (this.y > 0) {
								return this.y;
							}
						},
						marker: {
							states: {
								hover: {
									fillColor: '#fff',
									lineWidth: 0,
									radius: 5,
									enabled: false
								},
								select: false
							}
						}
					},
					series: {
						allowPointSelect: false,
						connectNulls: true,
						dataLabels: {
							enabled: false,
							formatter () {
								if (this.y > 0) {
									return this.y;
								}
							}
						},
						animation: {
							duration: 600
						}
					},
					bar: {
						borderColor: '',
						grouping: false
					}
				},
				tooltip: {
					style: {
						padding: 20,
						fontWeight: 'bold',
						color: '#fff',
						fontFamily: flywheelFonts
					},
					useHTML: true,
					backgroundColor: '#50c6db',
					borderColor: '#50c6db',
					shadow: false,
					headerFormat: '<h2 class="highchart-tooltip-header">{point.key}</h2>',
					pointFormat: '<div class="highchart-tooltip-data">{point.y} visits</div>'
				},
				credits: {
					enabled: false
				}
			});
		});
	}
};
