class InvoiceCalculator {
	constructor (lineItems, taxRates, discount) {
		this.lineItems = lineItems;
		this.taxRates = taxRates;
		this.discount = discount;
	}

	subtotal = () => {
		return this.lineItems.map((lineItem) => lineItem.amount).reduce((prev, curr) => prev + curr);
	};

	taxTotal = (discounted = true) => {
		if (this.taxRates.length) {
			return this.taxRates.map((taxRate) => this.individualLineItemTaxPrice(taxRate, discounted)).reduce((prev, curr) => prev + curr);
		}

		return 0;
	};

	discountTotal = () => {
		return this.lineItems.map((lineItem) => this.individualLineItemDiscountedPrice(lineItem)).reduce((prev, curr) => prev + curr);
	}

	discountedSubtotal = () => {
		if (!this.discount) {
			return this.subtotal();
		}

		return this.subtotal() - this.discountTotal();
	}

	total = () => {
		return this.discountedSubtotal() + this.taxTotal();
	}

	individualLineItemTaxPrice = (taxRate, applyDiscount = true) => {
		const rate = parseFloat(taxRate.percentage);

		if (rate !== 0) {
			const price = this.lineItems.map((lineItem) => {
				const amount = lineItem.amount;
				const discounted = amount - this.individualLineItemDiscountedPrice(lineItem);

				if(applyDiscount) {
					return this.#roundPrice((rate * 0.01) * discounted);
				}

				return this.#roundPrice((rate * 0.01) * amount);

			}).reduce((prev, curr) => prev + curr);

			return price;
		}

		return 0;
	};

	individualLineItemDiscountedPrice = (lineItem) => {
		if (!this.discount) {
			return 0;
		}

		const discountPercentage = this.discount.amountOff ? (parseFloat(this.discount.amountOff) / this.subtotal()) : parseFloat(this.discount.percentOff) * 0.01;
		const price = parseFloat(lineItem.amount);

		if (this.discount.amountOff) {
			return price * discountPercentage;
		}

		return this.#roundPrice(price * discountPercentage);
	};

	calculatedTaxRates = () => {
		if (this.taxRates.length) {
			return this.taxRates.map((taxRate) => this.individualLineItemTaxPrice(taxRate));
		}
	};

	#roundPrice = (num) => {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	}
}

export default InvoiceCalculator;
