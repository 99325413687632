// Color: String hex value
const hexValidation = function (color) {
	return /^#([0-9A-F]{3}){1,2}$/i.test(color);
}

// Color: String hex value
const hexToRgb = function (color) {
	let hex = color.substring(1).split('');

	if (hex.length== 3){
		hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]];
	}

	hex = '0x' + hex.join('');

	return [(hex>>16)&255, (hex>>8)&255, hex&255];
}

// Color: Array of rgb values
const rgbToOpac = function (color) {
	let hex = [];

	color.forEach(val => {
		hex.push(255 - 0.2 * (255 - val));
	})

	return "rgb(" + hex.join(', ') + ")";
}

export {
	hexValidation,
	hexToRgb,
	rgbToOpac
}
