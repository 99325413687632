/**
 * Returns a parent or grandparent DOM node
 *
 * EXAMPLE:
 	import parents from 'helpers/parents';

	const element = document.querySelector('.close');
	parents(element, 'alert');
	// will return the parent element .alert
 */

// Returns a parent or grandparent DOM node
//

const retrieveParentElement = (element, classname) => {
	if (element.classList.contains(classname)) {
		return element;
	}

	return element.parentNode && retrieveParentElement(element.parentNode, classname);
}

export default retrieveParentElement;
