import { flatten, excludes } from 'helpers/array';
import { camelCaseToSnakeCase, snakeCaseToCamelCase } from 'helpers/strings';

const dig = (obj, ...namespace) => {
	namespace = flatten(namespace);

	while (namespace.length) {
		if (obj == null) break;
		obj = obj[namespace.shift()];
	}

	return obj;
}

const slice = (obj, ...wanted) => {
	let target = {};

	wanted.forEach((key) => {
		target[key] = obj[key];
	});

	return target;
}

const except = (obj, ...unwanted) => {
	let wanted = Object.keys(obj).filter(k => excludes(unwanted, k));
	let target = {};

	wanted.forEach((key) => {
		target[key] = obj[key];
	});

	return target;
}

const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';

const transformObjectKeys = (obj, fn) => {
	if (!isObject(obj)) {
		return obj;
	}

	return Object.fromEntries(
		Object.entries(obj).map(
			([key, value]) => [fn(key), transformObjectKeys(value, fn)]
		)
	);
};

const objectKeysCamelCaseToSnakeCase = (obj) => transformObjectKeys(obj, camelCaseToSnakeCase);

const objectKeysSnakeCaseToCamelCase = (obj) => transformObjectKeys(obj, snakeCaseToCamelCase);

export { dig, slice, except, isObject, transformObjectKeys, objectKeysCamelCaseToSnakeCase, objectKeysSnakeCaseToCamelCase };
