export default {
	init () {
		if (document.documentElement.clientWidth >= 1024) {
			window.scrollBy(0, -85);
		}

		window.addEventListener('hashchange', () => {
            if (document.documentElement.clientWidth >= 1024) {
                window.scrollBy(0, -85);
            }
        });
	}
};
