export default (selector) => {
//Provide a CSS-style selector to target the desired elements, e.g., '.gauge'
//This keeps matching elements from animating until they're fully within the viewport

	if ('IntersectionObserver' in window) {
		const animatedElements = document.querySelectorAll(selector);

		const onChange = (changes, observer) => {
			changes.forEach((change) => {
				if (change.intersectionRatio > 0 || change.isIntersecting) {
					setTimeout(() => {
						change.target.classList.remove('no-animation');
					}, 50);
					observer.unobserve(change.target);
				}
			});
		};

		const config = {
			root: null,
			rootMargin: '0px',
			threshold: 1
		};

		const gaugeObserver = new IntersectionObserver(onChange, config);

		animatedElements.forEach((el) => {
			// Make sure the element isn't already in the viewport
			const rect = el.getBoundingClientRect();
			if ((rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)) || (window.innerHeight || document.documentElement.clientHeight) - rect.top > 0) {
				return;
			}
			el.classList.add('no-animation');
			gaugeObserver.observe(el);
		});
	}
};
